@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	white-space: wrap;
}

fieldset {
	border: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}
